<template>
  <div class="flex" :class="classes">
    <label v-if="!hideLabel" class="text-sm" :for="componentId" v-text="label" />
    <div :id="componentId" class="button-bar flex flex-no-wrap" :class="wrapperClass">
      <yb-button
        v-for="option in options"
        :key="option.id"
        v-tooltip="option.tooltip"
        :data-test-id="option.id"
        :class="[selected(option) ? btnDown : 'btn-white', option.class ? option.class : '']"
        class="btn"
        :label="option.label"
        :disabled="disabled"
        @click="!disabled && changed(option)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { v1 as uuidv1 } from 'uuid'

export default defineComponent({
  props: {
    modelValue: {
      type: [Object, String],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    vertical: {
      type: Boolean,
      defaultValue: true
    },
    btnDown: {
      type: String,
      default: 'btn-yellow'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    classes() {
      const result = []
      result.push('flex')
      if (this.vertical) {
        result.push('flex-col')
      } else {
        result.push('flex-row')
        result.push('items-center')
        result.push('flex-nowrap')
        result.push('justify-between')
        if (this.label) {
          result.push('space-x-2')
        }
      }
      return result
    }
  },
  beforeCreate() {
    this.componentId = `button-group-${uuidv1()}`
  },
  methods: {
    selected(option) {
      if (this.type === 'object') {
        return option.id === this.modelValue.id
      } else {
        return option.id === this.modelValue
      }
    },
    changed(option) {
      if (this.type === 'object') {
        this.$emit('update:modelValue', option)
      } else {
        this.$emit('update:modelValue', option.id)
      }
    }
  }
})
</script>

<style scoped lang="postcss">
.button-bar button.btn {
  @apply border-l-0 border-yb-brand-primary rounded-none border-r border-t border-b;
  @apply dark:border-yb-gray-medium;
}
.button-bar button:first-child {
  @apply rounded-l border-l;
}
.button-bar  button:last-child {
  @apply rounded-r;
}
.btn.btn-yellow {
  @apply bg-yb-selection text-yb-gray-darker border-t border-b;
  @apply dark:bg-yb-selection dark:text-yb-gray-darker dark:border-yb-gray-medium;
}

.btn.btn-yellow:hover:not(:disabled) {
   @apply bg-yb-selection dark:text-white;
}

</style>
